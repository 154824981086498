import { BaseQueryApi, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store'; // Adjust the path as needed


const baseQueryWithDynamicUrl = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    const state = api.getState() as RootState;
    const baseUrl = state.app.baseUrl;
    console.log(baseUrl);
    if (!baseUrl) {
        throw new Error("Base URL is not defined");
    }

    const rawBaseQuery = fetchBaseQuery({ baseUrl });
    return rawBaseQuery(args, api, extraOptions);
};


export const chatApi: any = createApi({
    reducerPath: 'chatApi',
    baseQuery: baseQueryWithDynamicUrl,
    endpoints: (builder) => ({
        getAIResponse: builder.mutation({
            query: (text) => ({
                url: `chat`,
                method: 'POST',
                body: {
                    "text": text,
                }
            }),
        }),
        login: builder.mutation({
            query: ({ username, password }) => ({
                url: `login`,
                method: 'post',
                body: {
                    "username": username,
                    "password": password
                }
            }),
        }),
        attachFile: builder.mutation({
            query: (file) => ({
                url: "attachfile",
                method: "POST",
                body: file
            })
        })
    }),
})

export const {
    useGetAIResponseMutation,
    useLoginMutation,
    useAttachFileMutation
} = chatApi;
