import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'

// Define a type for the slice state
interface ChatState {
    chatIndex: number,
    chat: {}
}

// Define the initial state using that type
const initialState: ChatState = {
    chatIndex: -1,
    chat: {}
}

export const chatSlice = createSlice({
    name: 'chatReducer',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {

        setChatIndex: (state, action: PayloadAction<number>) => {
            state.chatIndex = action.payload
        },
        setChat: (state, action: PayloadAction<{}>) => {
            state.chat = action.payload
        },
    },
})


export const { setChatIndex, setChat } = chatSlice.actions
export const getChatIndex = (state: RootState) => state.chat.chatIndex
export const getChat = (state: RootState) => state.chat.chat


export default chatSlice.reducer