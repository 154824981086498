import React, { useState, useEffect } from "react";
import './index.scss'
import { ReactComponent as BotIcon } from '../../../../../../assets/bot-icon.svg';
import { useGetAIResponseMutation } from "../../../../../../api/chatApi";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Divider } from "@mui/material";
type Props = { userMessage?: any, text?: string }

const AiMessage = (props: Props) => {
    const [isLoading, setLoading] = useState(false);
    const [message, setMessage] = useState(props.text ? props.text : "");
    const [getAIMessageTrigger] = useGetAIResponseMutation();
    const [sources, setSources] = useState<string[]>([]);

    useEffect(() => {
        const fetchAIMessage = async () => {
            if (props.userMessage) {
                setLoading(true);
                try {
                    const res: any = await getAIMessageTrigger(props.userMessage).unwrap();
                    console.log('API response:', res);  // Debugging line
                    if (res.result) {
                        setMessage(res.result);
                        if (res.source_documents) {
                            const uniqueSources = Array.from(new Set(res.source_documents.map((el: any) => {
                                const lastIndex = el.metadata?.source.lastIndexOf("\\");
                                return lastIndex !== -1 ? el.metadata?.source.substring(lastIndex + 1) : el.metadata?.source;
                            }))) as string[];
                            setSources(uniqueSources);
                        }
                    } else {
                        toast.error('Unexpected response structure');
                        console.error('Unexpected response structure:', res);  // Debugging line
                    }
                } catch (error) {
                    toast.error('Error fetching AI response');
                    console.error('Error fetching AI response:', error);  // Debugging line
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchAIMessage();
    }, [props.userMessage, getAIMessageTrigger]);

    return (
        <div className="bot-container">
            <div>
                <BotIcon className="bot-icon" />
            </div>
            <div className="bot-message">
                <div>{isLoading ? "Loading..." : message}</div>
                {sources.length > 0 && (
                    <>
                        <Divider />
                        <div>
                            {sources.map((el, index) => (
                                <React.Fragment key={index}>
                                    {index > 0 && ", "}
                                    <a href={el}>{el}</a>
                                </React.Fragment>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default AiMessage;
