import React, { useEffect, useRef } from "react";
import './index.scss'
type Props = { messages: any[] }


export default function Messages(props: Props) {
    const messageRef = useRef<null | HTMLDivElement>(null);
    useEffect(() => {
        if (messageRef.current) {

            messageRef?.current.scrollIntoView({ block: "end", behavior: "smooth" });
        }
        console.log(props.messages);
    });
    return (
        <div className="messages">
            {props.messages}
            <div id={"messageRef"} ref={messageRef} />
        </div>
    );
}
