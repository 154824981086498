import React from 'react'
import './index.scss'
type Props = { handleSelectPrompt: any }

const WelcomeMessage = (props: Props) => {
    const containerData = [
        { description: 'What is a Semiotic model?' },
        { description: 'What are Minimal Perfect Hash Functions?' },
        { description: "What's Pascal's Reserved Words" },
        { description: 'Tell me about JSON parsers' },
    ];

    return (
        <div className="welcomeContainer">

            <div className="container">
                <div>
                    <h1 className='welcomeTitle'>
                        Welcome to ETIC's <b>Brainy Assisstant Tool</b>
                    </h1>
                    <h1 className='welcomeTitle'>
                        Ask your questions below.
                    </h1>
                </div>
                <div className='container-row'>

                    {containerData.map((item, index) => (
                        <div className="container-item" onClick={() => props.handleSelectPrompt(item.description)} key={index}>
                            <p className='description'>{item.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default WelcomeMessage;