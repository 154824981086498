import React, { useEffect, useRef, useState } from 'react';
import { Grid, Box, FormControl, InputLabel, OutlinedInput, InputAdornment, Button, IconButton } from '@mui/material';
import { ReactComponent as SendIcon } from '../../../../assets/send.svg';
import AttachFileIcon from "../../../../assets/attach-file.png";
import './index.scss';
import AiMessage from './components/aiMessage';
import UserMessage from './components/userMessage';
import Messages from './components/messages';
import WelcomeMessage from './components/welcomeMessage';
import { useSelector } from 'react-redux';
import { useAttachFileMutation } from '../../../../api/chatApi';

type Props = { userMessages?: any[], aiMessages?: any[] }

const ChatContainer = (props: Props) => {
    const [messages, setMessages] = useState<JSX.Element[]>([]);
    const { inputType } = useSelector((state: any) => state.app);
    const [attachFile] = useAttachFileMutation();
    useEffect(() => {
        if (props.userMessages && props.aiMessages) {
            let newUserMessages: JSX.Element[] = [];
            const botMessages = props.aiMessages;
            props.userMessages.forEach((user, index) => {
                newUserMessages.push(<UserMessage key={messages.length + 1} text={user} />, <AiMessage
                    key={messages.length + 2}
                    text={botMessages[index]}
                />)

            })
            setMessages(newUserMessages);
        }
    }, [])

    const inputRef = useRef<HTMLInputElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null); // Reference to file input element

    const handleSubmit = (event: any) => {
        event.preventDefault();
        sendMessage();
    };

    const sendMessage = () => {
        if (inputRef.current && inputRef.current.value) {
            const inputValue = inputRef.current.value;
            send(inputValue);
            inputRef.current.value = '';
        }
    };

    const send = async (text: any) => {
        const newMessages =
            messages.concat(
                <UserMessage key={messages.length + 1} text={text} />,
                <AiMessage
                    key={messages.length + 2}
                    userMessage={text}
                />
            );
        console.log(newMessages);
        setMessages(newMessages);
    };

    const handlePromptClicked = (input: any) => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.value = input;
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            sendMessage();
        }
    };

    const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            await attachFile(file).unwrap().then((res: any) => {
                console.log(res);
            })
            // Process the file here (e.g., upload to server)
            console.log('Selected file:', file);
        }
    };

    const handleAttachFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Grid item xs={8} sm={9}>
            <Box sx={{ height: '100%' }} className="chatbotContainer">
                {
                    messages.length > 0 ?
                        <Messages messages={messages} /> :
                        <WelcomeMessage handleSelectPrompt={handlePromptClicked} />
                }
                <form id='user_input' onSubmit={handleSubmit}>
                    <FormControl variant="outlined" className='submitInput'  >
                        <InputLabel>Ask me something</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            inputRef={inputRef}
                            multiline
                            maxRows={3}
                            onKeyPress={handleKeyPress}
                            endAdornment={
                                <InputAdornment position="end">
                                    {
                                        inputType?.includes("documents") ?
                                            <IconButton onClick={handleAttachFileClick} >
                                                <img src={AttachFileIcon} aria-label="attach file" alt="Attach File" style={{ width: '36px' }} />
                                            </IconButton> : <></>
                                    }


                                    <IconButton form="user_input" className='sendIcon' type="submit">
                                        <SendIcon aria-label="submit form" />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileInputChange} />
                </form>
            </Box>
        </Grid>
    );
};

export default ChatContainer;
