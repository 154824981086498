import { createTheme } from '@mui/material/styles';

/* 
Edit this file to suit the brand colors 
*/
const theme = createTheme({
    palette: {
        primary: {
            main: '#D04A02', // Set the main color here 

        },
    },

    typography: {
        h2: {
            fontSize: '12px',
            fontWeight: 700

        },
        h1: {
            fontSize: '14px',
            fontWeight: 700

        },
        body2: {
            fontSize: '12px',

        },
        fontFamily: [
            'Open Sans', // set here the main font 
            'Roboto',
            'Arial',
            'sans-serif',
        ].join(','),
        button: {
            fontWeight: 700, // Set the font weight for buttons
            justifyContent: 'flex-start',
            maxWidth: '200px',
            width: '139px ',
            height: '29px',
            fontSize: '12px',
            textTransform: 'none',
        },

    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFFFFF',

                },
            },
        },
    },


});

export default theme;