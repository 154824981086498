import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import Login from './pages/login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ModuleSelector from './pages/selector';

export default () => {
  return (

    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Login />} />
        <Route path="/modules" element={< ModuleSelector/>} />
      </Routes>
    </BrowserRouter>
  );
};
