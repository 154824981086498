import React, { useState } from 'react';
import { Container, TextField, Button, Typography } from '@mui/material';
import './index.scss';
import logo from '../../assets/logo_color.png'
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../api/chatApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [login, { isLoading }] = useLoginMutation();

    const navigate = useNavigate();

    const handleLogin = async () => {
        // You can remove the login API call
        navigate('/modules'); // Navigate to the home page
    };
  
    return (
        <Container className="login-container">
            <div className="centered-content">
                <img src={logo} alt="Logo" className="logo" />
                <form className="form">
                    <TextField
                        type="email"
                        label="Email"
                        fullWidth
                        margin="normal"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        type="password"
                        label="Password"
                        fullWidth
                        margin="normal"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault(); // Prevent default form submission behavior
                                handleLogin(); // Trigger login function
                            }
                        }}
                    />
                  
                    <div className='button-container'>

                        <Button
                            variant="contained"
                            color="primary"

                            className="loginButton"
                            onClick={handleLogin}
                            disabled={isLoading || !username || !password}
                        >
                            LOGIN
                        </Button>
                    </div>
                </form>
            </div>
        </Container >
    );
};

export default Login;