import { configureStore } from '@reduxjs/toolkit'
import chatReducer from './features/chat/chat'
import appReducer from './features/chat/appSlice'
import { chatApi } from './api/chatApi'


export const store = configureStore({
    reducer: {
        chat: chatReducer,
        app: appReducer,
        [chatApi.reducerPath]: chatApi.reducer,

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([chatApi.middleware]),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch