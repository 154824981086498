import React, { useEffect } from 'react'
import './index.scss'
import { Grid } from '@mui/material';
import Sidebar from './components/sidebar';
import ChatContainer from './components/chatContainer';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type Props = {}

const Home = (props: Props) => {
    const navigate = useNavigate();
    const { model, inputType, baseUrl } = useSelector((state: any) => state.app);
    const chatHistory = [{ name: "PharmaTech Expo - Barcelona - Spain", }, { name: "Compliance Regulations" }, { name: "Fintech & E-Commerce" }];

    useEffect(() => {
        console.log(model, inputType, baseUrl);
        if (!model || !inputType || !baseUrl) {
            navigate('/modules');
        }
    }, [model, inputType, baseUrl, navigate]);

    return (
        <>
            {/* <hr className='redLineHeader'></hr> */}
            <div className='homeContainer'>
                <Grid container className='gridContainer'>
                    <Sidebar chatHistory={chatHistory} />
                    <ChatContainer />
                </Grid>
            </div>
        </>

    )
}
export default Home;