import React from 'react'
import './index.scss'

type Props = { text: string }

const UserMessage = (props: Props) => {
    return (
        <div className="message-container">
            <div className="user-message">{props.text}</div>
        </div>
    )
}

export default UserMessage;