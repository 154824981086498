import React from 'react'
import { Grid, Box, Paper, Button, Typography } from '@mui/material';
import logo from '../../../../assets/logo.png'
import { ReactComponent as ChatIcon } from '../../../../assets/new.svg';
import { ReactComponent as LogoutIcon } from '../../../../assets/logout.svg';
import { ReactComponent as PersonIcon } from '../../../../assets/person.svg';
import './index.scss'
import ChatHistory from '../chatHistory';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

type Props = { chatHistory: any[] }

const Sidebar = (props: Props) => {
    const { model } = useSelector((state: any) => state.app);

    const navigate = useNavigate();
    const handleLogout = () => {
        navigate('/');
    }


    return (


        <Grid item xs={3} sm={2}>
            <Paper elevation={3} className="sidebarContainer">
                <div className='headerContainer'>
                    <Box className="logoContainer">
                        <img src={logo} alt="Logo" width="80" />
                    </Box>
                    <Typography variant="h2" className='modelName'>{model}</Typography>

                </div>
                <div className='line'></div>
                <Button variant="outlined" color="primary" className="buttonContainer">
                    <ChatIcon style={{ marginRight: 8 }} /> New Chat
                </Button>

                {/* <Box className="chatContainer">
                    {
                        props.chatHistory.map((item, index) => {
                            return (
                                <ChatHistory chatName={item.name} key={index} index={index} />
                            )

                        })
                    }
                </Box> */}

                <Box className="logoutButtonContainer">

                    <div className='accountIcon'>
                        <Typography variant="h2"><span className='icon'><PersonIcon style={{ marginRight: 8 }} />Test Account </span></Typography>
                    </div>
                    <Typography variant="h2" className="underlineText" onClick={handleLogout}>

                        Logout
                    </Typography>
                </Box>
            </Paper>

        </Grid>
    )
}

export default Sidebar; 