import React from 'react';
import { Container, Button, Typography, Card, CardContent, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setModel, setInputType, setBaseUrl } from '../../features/chat/appSlice';
import './index.scss';

const ModuleSelector: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleModuleSelection = (module: string, inputType: any[], baseUrl: string) => {
        dispatch(setModel(module));
        dispatch(setInputType(inputType));
        dispatch(setBaseUrl(baseUrl));
        navigate('/home');
    };

    return (
        <Container className="module-selector-container">
            <div className="centered-content">
                <Typography variant="h6" style={{ marginTop: "60px" }}>
                    Select a Brainy
                </Typography>
                <div className='model-container'>
                    <Card sx={{ maxWidth: 345, height: '100%' }}>
                        <CardContent style={{ height: '230px' }}>
                            <Typography variant="h6" component="div">
                                Chat with Documents
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                This feature is currently under development. It is intended for individuals who work in Business Analysis to expedite the analysis and reporting process from documents containing charts.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={() => handleModuleSelection('Chat with Documents', ["string"], 'http://34.68.175.51/')}>
                                Go to this model
                            </Button>
                        </CardActions>
                    </Card>
                    <Card sx={{ maxWidth: 345, height: '100%' }}>
                        <CardContent style={{ height: '230px' }}>
                            <Typography variant="h6" component="div">
                                Structured Data
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                This feature is intended for individuals working with large datasets or databases to make the process of extraction and analysis more efficient and accurate.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={() => handleModuleSelection('Structured Data', ["string"], 'http://34.171.182.126/')}>
                                Go to this model
                            </Button>
                        </CardActions>
                    </Card>
                    <Card sx={{ maxWidth: 345, height: '100%' }}>
                        <CardContent style={{ height: '230px' }}>
                            <Typography variant="h6" component="div">
                                DePlot
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                This feature is intended for Business Analysts to de-plot large documents containing charts and expedite the analysis process.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={() => handleModuleSelection('DePlot', ["string", "documents"], 'http://34.171.182.126/')}>
                                Go to this model
                            </Button>
                        </CardActions>
                    </Card>
                </div>
            </div>
        </Container>
    );
};

export default ModuleSelector;
