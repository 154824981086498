import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        model: null,
        inputType: null,
        baseUrl: null,
    },
    reducers: {
        setModel: (state, action) => {
            state.model = action.payload;
        },
        setInputType: (state, action) => {
            state.inputType = action.payload;
        },
        setBaseUrl: (state, action) => {
            state.baseUrl = action.payload;
        },
    },
});

export const { setModel, setInputType, setBaseUrl } = appSlice.actions;

export default appSlice.reducer;
